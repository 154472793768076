import { useState, useEffect, useRef  } from "react";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper"; // Import Swiper type
import { apiClient } from '@common/http/query-client';
// Import Swiper styles
import "swiper/css";
import "../styles.css";

export default function FetchApi() {
  const [posts, setPosts] = useState<any[]>([]);
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [currentKey, setCurrentKey] = useState<string>('');  // Track the current video key to force re-render
  const [isControl, setIsControl] = useState(false); // State to manage the mute status
  const playerRef = useRef<ReactPlayer | null>(null);; // Ref to hold the ReactPlayer instance

  // const BASE_URL = 'http://localhost:7000/projects-xampp/Toyota-Marketing-Collateral-Platform-Demo';
  // const BASE_URL = 'http://localhost/Toyota-Marketing-Collateral-Platform-Demo';
  const BASE_URL = 'https://demo.tmp-mc.sys.network';
  const API_URL = BASE_URL + '/api/v1/file-entries';
  // get hash from url
  const url = window.location.href
  const res = url.split("/");
  const pos = res.indexOf('tv');
  const hash = res[pos+1];

  interface Post {
    id: string; // or number, depending on your data
    workspace_id: number;
    // other properties of the post...
  }

  // Fetch data using async/await with the Fetch API
  const fetchUsingAsyncAwaitWithFetchApi = async () => {
    const params = {
      section: 'folder',
      folderId: hash,
      workspaceId: 0,
      orderBy: 'updated_at',
      orderDir: 'desc',
      page: 1,
    };
    return apiClient
      .get(`drive/file-entries`, {params})
      .then(response => { 
        console.log(response.data.data);
        // setPosts(response.data.data);
        return response.data.data;
       });
  };

  // useEffect(() => {
  //   // Trigger fetching method on component mount
  //   fetchUsingAsyncAwaitWithFetchApi();
  // }, []); // Run the effect only once on component mount

  // const handleVideoEnd = async () => {
  //   if (swiper) {
  //     const currentIndex = swiper.realIndex; // Use realIndex to get the index of the actual slide
  //     const totalSlides = posts.length;

  //     // if we are on the last of the slider before going back to the first slider
  //     if (currentIndex === totalSlides - 1) {
  //       console.log("End of loop detected, fetching more videos...");
  //       const updatedPosts = await fetchUsingAsyncAwaitWithFetchApi();
  //       console.log("Done fetching");

  //       // if there's only 1 post, reset the video back to 0 to auto play
  //       console.log("total posts : " + updatedPosts.length);
  //       if(updatedPosts.length === 1) {

  //         // console.log("Starting replay");
         
  //         if (playerRef.current) {
  //           console.log("Replay current slider video");
  //           playerRef.current.seekTo(0); // Restart video from the start
  //         } else {
  //           console.error("playerRef is not available");
  //         }

    
  //       } else {
  //         // this should force the slider to move back to the first slider
  //         swiper?.slideNext(1000); // Move to the next slide
  //       }
        
  //     } else {
  //       // this will continue moving the slider
  //       swiper?.slideNext(1000); // Move to the next slide
  //     }
  
  //   }
  // };

  // const handleVideoEnd = () => {
  //   if (swiper) {
  //     const currentIndex = swiper.realIndex; // Get the current index
  //     const totalSlides = posts.length;

  //     // Move to the next slide
  //     if (currentIndex < totalSlides - 1) {
  //       swiper.slideNext(1000); // Transition to the next slide
  //     } else {
  //       console.log('End of loop reached. Fetching new videos...');
  //       fetchUsingAsyncAwaitWithFetchApi();
  //       swiper.slideTo(0); // Loop back to the first slide
  //     }
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      const initialVideos = await fetchUsingAsyncAwaitWithFetchApi();
      setPosts(initialVideos); // Set the initial posts after fetching
      setCurrentKey(initialVideos[0]?.id || ''); // Set the initial key to trigger first render
    };
    fetchData();
  }, []);

  // Determine if the device is a tablet or mobile
  useEffect(() => {
    const checkDevice = () => {
      const isTabletOrMobile = window.matchMedia('(max-width: 1080px)').matches;
      setIsControl(isTabletOrMobile);
    };

    // Check on mount
    checkDevice();

    // Add an event listener to handle screen resizing
    window.addEventListener('resize', checkDevice);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', checkDevice);
    };
  }, []);


   // Handle the end of the last video and fetch new list
   const handleVideoEnd = async () => {
    if (swiper) {
      const currentIndex = swiper.realIndex; // Get the current index of the active slide
      const totalSlides = posts.length;

      // Check if we are on the last slide
      if (currentIndex === totalSlides - 1) {
        console.log("End of the last video detected, fetching new videos...");
        
        // Fetch new videos after the last video ends
        const newVideos = await fetchUsingAsyncAwaitWithFetchApi();
        
        // If the fetched list is the same as the current posts, force a re-render
        const isSameVideo = newVideos.length === 1;
        if (isSameVideo) {
          console.log("Same video, forcing re-render");
          setPosts(newVideos);
          setCurrentKey(Date.now().toString());  // Use a timestamp to force re-render and restart the video
        } else {
          setPosts(newVideos); // Update the posts with the new videos
          setCurrentKey('');  // Change the key to force re-render
        }

        // If there is only one video, replay it
        if (newVideos.length === 1) {
          console.log("Replay current slider video");
        } else {
          // Move to the first slide if there are multiple videos
          swiper.slideTo(0); // Go to the first slide to start the new video list
        }
      } else {
        // If it's not the last slide, just move to the next slide
        swiper?.slideNext(1000);
      }
    }
  };

  return (
    <>
      <Swiper className="mySwiper"
      loop={true}
      onInit={(ev) => {setSwiper(ev)}}
      // onReachEnd={() => {
      //   console.log("End of loop reached");
      //   fetchUsingAsyncAwaitWithFetchApi(); // Fetch updated videos at the end of the loop
      // }}
      >
      {posts.map((post, key) => (
        <SwiperSlide key={key}>
          {({ isActive }) => (
            
              <ReactPlayer
              className='react-player'
              ref={playerRef}
              key={currentKey || post.id} // Use currentKey to trigger re-render
              url={API_URL + '/' + post.id + '?workspaceId=' + post.workspace_id + '&thumbnail='}
              width="100%"
              height="100%"
              volume={1}
              playsinline={true}
              controls={isControl}
              onEnded={handleVideoEnd}
              playing={isActive && !isControl} />
           
          )}
        </SwiperSlide>
      ))}
      </Swiper>
    </>
  );
};