import {Fragment} from 'react';
import {DefaultMetaTags} from '@common/seo/default-meta-tags';
import VideoCarousel from "./video-carousel";


export function TvPage() {
  return (
      <Fragment>
        <DefaultMetaTags />
        <div>
          <VideoCarousel />
        </div>
      </Fragment>
  );
}
